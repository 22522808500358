import React, { useState, useEffect, useRef } from 'react';
import Player from '@vimeo/player';
import { RichText } from 'prismic-reactjs';
import Layout from '../components/Layout';
import styled from 'styled-components';
import { Link } from 'gatsby';
import getImageUrl from '../getImageUrl';
import { red, green, tablet, desktop } from '../constants';
import EmbedContainer from '../components/EmbedContainer';
import SEO from '../components/seo';

export const query = graphql`
  {
    prismic {
      index(uid: "homepage", lang: "en-gb") {
        hero_video
        hero_video_2
        hero_video_3
        cta_text
        introduction
        size
      }
      category_list(uid: "categories", lang: "en-gb") {
        categories {
          category {
            ... on PRISMIC_Category {
              title
              _meta {
                uid
              }
            }
          }
        }
      }
    }
  }
`;

const Videos = styled.div`
  display: grid;
  grid-template: 1fr / 1fr;
  z-index: 105;
  cursor: pointer;
`;

const VideoContainer = styled.div`
  margin: 0 auto;
  max-height: calc(100% - 192px);
  overflow: hidden;
  grid-column: 1 / 1;
  grid-row: 1 / 1;
  width: calc(100% - 48px);
  margin: ${({ margin }) => margin};
  z-index: ${({ selected }) => selected ? '102' : '100'};
  position: relative;

  @media (min-width: ${desktop}) {
    width: calc(100% - 144px);
  }
`;

const Thumbnail = styled.img`
  width: 100%;
`;

const CTASection = styled.div`
  display: block;
  margin: 2rem auto 6rem;
  text-align: center;
`;

const CTA = styled(Link)`
  padding: 12px;
  margin 20px;
  font-weight: 500;
  font-size: 1.25rem;
  color: ${red};

  @media (min-width: ${tablet}) {
    font-size: 1.5rem;
  }
`;


const fontSizes = {
  Normal: { mobile: 1, tablet: 1.25, desktop: 1.5 },
  Large: { mobile: 1.25, tablet: 1.5, desktop: 1.75 },
  Small: { mobile: 1, tablet: 1, desktop: 1.25 }
};

const Intro = styled.div`
  & p {
    font-size: ${({ size }) => fontSizes[size]?.mobile}rem;
    line-height: 1.5;
    color: ${green};
    font-weight: 300;

    @media (min-width: ${tablet}) {
      font-size: ${({ size }) => fontSizes[size]?.tablet}rem;
    }

    @media (min-width: ${desktop}) {
      font-size: ${({ size }) => fontSizes[size]?.desktop}rem;
    }

    margin-bottom: 1rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  margin-bottom: 2rem;
`;

const ClickableOverlay = styled.div`
  cursor: pointer;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  min-height: 100%;
  z-index: 100;
`;

const Index = ({ data }) => {
  const { index } = data.prismic;
  const { cta_text: cta, hero_video: heroVideo, introduction, hero_video_2: heroVideo2, hero_video_3: heroVideo3, size = 'Normal' } = index;

  const workUid =
    data?.prismic?.category_list?.categories?.[0]?.category?._meta?.uid;

  const [selectedVideo, setSelectedVideo] = useState(1);

  const player1 = useRef();
  const player2 = useRef();
  const player3 = useRef();

  useEffect(() => {
    const video1 = document.querySelector('#video-1');
    if (video1) {
      player1.current = new Player(video1);
    }

    const video2 = document.querySelector('#video-2');
    if (video2) {
      player2.current = new Player(video2);
    }
    
    const video3 = document.querySelector('#video-3');
    if (video3) {
      player3.current = new Player(video3);
    }
  }, []);

  return (
    <Layout>
      <SEO title="Home" />
      {introduction && <Intro size={size}>
        <RichText render={introduction} />
      </Intro>}
      <Videos>
        {heroVideo2 && <VideoContainer margin="0 auto 0 0" selected={selectedVideo === 0}>
          <ClickableOverlay onClick={() => {
            setSelectedVideo(0)
            player1.current.play();
            player2.current && player2.current.pause();
            player3.current && player3.current.pause();
          }} />
          <EmbedContainer>
            <iframe
              src={`https://player.vimeo.com/video/${heroVideo2.video_id}?background=1&autoplay=0`}
              title={heroVideo2.title}
              frameBorder="0"
              id="video-1"
            ></iframe>
            <Thumbnail
              src={getImageUrl(heroVideo2.thumbnail_url)}
              alt={heroVideo2.title}
            />
          </EmbedContainer>
        </VideoContainer>}
        {heroVideo && <VideoContainer margin="96px auto" selected={selectedVideo === 1}>
        <ClickableOverlay onClick={() => {
            setSelectedVideo(1)
            player2.current.play();
            player1.current && player1.current.pause();
            player3.current && player3.current.pause();
          }} />
          <EmbedContainer>
            <iframe
              src={`https://player.vimeo.com/video/${heroVideo.video_id}?background=1&autoplay=1`}
              title={heroVideo.title}
              frameBorder="0"
              id="video-2"
            ></iframe>
            <Thumbnail
              src={getImageUrl(heroVideo.thumbnail_url)}
              alt={heroVideo.title}
            />
          </EmbedContainer>
        </VideoContainer>}
        {heroVideo3 && <VideoContainer margin="192px 0 0 auto" selected={selectedVideo === 2}>
        <ClickableOverlay onClick={() => {
            setSelectedVideo(2)
            player3.current.play();
            player1.current && player1.current.pause();
            player2.current && player2.current.pause();
          }} />
          <EmbedContainer>
            <iframe
              src={`https://player.vimeo.com/video/${heroVideo3.video_id}?background=1&autoplay=0`}
              title={heroVideo3.title}
              frameBorder="0"
              id="video-3"
            ></iframe>
            <Thumbnail
              src={getImageUrl(heroVideo3.thumbnail_url)}
              alt={heroVideo3.title}
            />
          </EmbedContainer>
        </VideoContainer>}
      </Videos>
      {workUid && cta && <CTASection>
        <CTA to={`/category/${workUid}`}>{cta}</CTA>
      </CTASection>}
    </Layout>
  );
};

export default Index;
